import { DefaultHttpClient } from '@microsoft/signalr';
import { refreshAndStoreToken } from "./api";

export class CustomHttpClient extends DefaultHttpClient {
	constructor() {
    	super(console); // the base class wants a signalR.ILogger
  	}

	public async send(request: signalR.HttpRequest): Promise<signalR.HttpResponse> {
		request.headers = request.headers || {};
		request.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
		
		try {
			const response = await super.send(request);
			return response;
		} catch (e) {
			if (e.statusCode === 401) {
				const newToken = await refreshAndStoreToken();
				request.headers['Authorization'] = `Bearer ${newToken}`;
			} else {
				throw e;
			}
		}

		return await super.send(request);
	}
}